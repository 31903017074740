import React from 'react';
import './LandingPage.css'; // Import the CSS for styling

const LandingPage = () => {
  return (
    <div>
      <header className="header">
        <div className="logo">DEVER</div>
        <nav className="nav-links">
          <a href="#features">Features</a>
          <a href="#solutions">Solutions</a>
          <a href="/pricing">Pricing</a>
        </nav>
      </header>

      <section className="hero">
        <div className="hero-text">
          <h1>Your AI Developer</h1>
          <p>
            DEVER automates coding, creating, updating and reviewing GitHub Pull Requests, enabling you to build and improve your codebase without any coding skills. 
            <br></br>
            Save time and money with your AI Developer.
          </p>
          <button className="cta-button" onClick={() => (window.location.href = '/auth')}>
            Try it for Free
          </button>
        </div>
        <div className="hero-image">
          <img
            src="LOGO DEVER.png"
            alt="AI Developer Screenshot"
          />
        </div>
      </section>

      {/* 🔥 YouTube Video Section (Integrated Below Everything) */}
      <section className="video-section">
        <h2 className="video-title">Watch DEVER AI in Action</h2>
        <div className="video-container">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/0RoKpZwnfrQ"
            title="DEVER AI Demo Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
