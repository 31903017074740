import React from 'react';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    const navigate = useNavigate();
    const isAuthenticated = !!localStorage.getItem('access_token');

    const handleLogout = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('github_token');
        navigate('/auth', { replace: true });
    };

    const handleLogin = () => {
        navigate('/auth', { replace: true });
    };

    return (
        <nav style={styles.navbar}>
            {/* 🔥 Clickable Title - Redirects to Landing Page */}
            <div style={styles.logo} onClick={() => navigate('/')}>
                Your_AI_Developer
            </div>

            {/* 🔥 Show Login or Logout button based on authentication status */}
            <button 
                style={styles.authButton} 
                onClick={isAuthenticated ? handleLogout : handleLogin}
            >
                {isAuthenticated ? "Logout" : "Login"}
            </button>
        </nav>
    );
};

const styles = {
    navbar: {
        display: 'flex',
        justifyContent: 'space-between', // ✅ Keeps items spaced correctly
        alignItems: 'center',
        padding: '10px 20px', // ✅ Ensures proper spacing
        backgroundColor: '#222',
        color: '#fff',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 1000,
        height: '40px',
    },
    logo: {
        fontSize: '1.2rem',
        cursor: 'pointer', // ✅ Makes it clickable
        userSelect: 'none', // ✅ Prevents text selection
    },
    authButton: {
        padding: '6px 12px', // ✅ Proper padding
        fontSize: '0.9rem',
        border: 'none',
        borderRadius: '5px',
        backgroundColor: '#DC3545',
        color: '#fff',
        cursor: 'pointer',
        position: 'absolute',
        right: '50px', // ✅ Stays inside the screen
    },
};

export default Navbar;
