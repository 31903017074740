import React, { useState, useEffect } from 'react';
import api from '../api'; // Import the Axios instance

const PricingPage = () => {
    const [userStripeID, setuserStripeID] = useState(null);
    const [loading, setLoading] = useState(true);

    // Retrieve environment variables
    const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    const pricingTableId = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;
    const portalUrl = process.env.REACT_APP_STRIPE_PORTAL_URL;

    useEffect(() => {
        // Fetch the user email from the backend
        const fetchUserData = async () => {
            try {
                const response = await api.get('/auth/me'); // ✅ Corrected endpoint
                if (response.data?.stripe_customer_id) {
                    setuserStripeID(response.data.stripe_customer_id); // ✅ Use email as client-reference-id
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    // Redirect to Stripe customer portal
    const handleOpenCustomerPortal = () => {
        window.location.href = portalUrl;
    };

    return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
            <h1>Choose Your Subscription</h1>

            {loading ? (
                <p>Loading pricing options...</p>
            ) : (
                <>
                    {/* Stripe Pricing Table with Dynamic User Email */}
                    
                        <stripe-pricing-table
                            pricing-table-id={pricingTableId}
                            publishable-key={publishableKey}
                            client-reference-id={userStripeID} // ✅ Use the email as client-reference-id
                        >
                        </stripe-pricing-table>

                    {/* Button to open Stripe Customer Portal */}
                    <button
                        onClick={handleOpenCustomerPortal}
                        style={{
                            marginTop: '20px',
                            padding: '10px 20px',
                            backgroundColor: '#007bff',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer'
                        }}
                    >
                        Manage My Subscription
                    </button>
                </>
            )}
        </div>
    );
};

export default PricingPage;
