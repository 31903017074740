import React from 'react';

const PRButtons = ({ showTaskManager, showPRManager, disableTask, disablePR }) => {
  return (
    <div className="button-group">
      <button className="pr-button" onClick={showPRManager} disabled={disablePR}>
        {disablePR ? "Upgrade for Review PR" : "Review PR"}
      </button>
      <button className="pr-button" onClick={showTaskManager} disabled={disableTask}>
        {disableTask ? "Upgrade for Code PR" : "Code PR"}
      </button>
    </div>
  );
};

export default PRButtons;