import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../api'; // Import the configured Axios instance

const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [adminGitHubToken, setAdminGitHubToken] = useState("");
    const [isAdmin, setIsAdmin] = useState(false); // Track if the user is an admin

    useEffect(() => {
        const checkTokens = async () => {
            const urlParams = new URLSearchParams(window.location.search);

            if (urlParams.get('session_expired')) {
                alert('Votre session a expiré, veuillez vous reconnecter. Session expired, please log in again.');
                localStorage.removeItem('access_token');
                localStorage.removeItem('github_token');
                window.history.replaceState({}, document.title, window.location.pathname);
            }

            let accessToken = localStorage.getItem('access_token');
            let githubToken = localStorage.getItem('github_token');

            if (!accessToken || accessToken === "undefined") {
                console.warn("Access token is invalid, removing from localStorage.");
                localStorage.removeItem('access_token');
                accessToken = null;
            }

            if (accessToken && githubToken) {
                navigate('/dashboard', { replace: true });
            } else if (!accessToken) {
                navigate('/auth', { replace: true });
            }
        };

        const fetchUserRole = async () => {
            try {
                const response = await api.get('/auth/me'); // Endpoint to fetch user info
                if (response.data?.role === 'admin') {
                    setIsAdmin(true);
                }
            } catch (error) {
                console.error('Error fetching user role:', error);
            }
        };

        const urlParams = new URLSearchParams(location.search);
        const githubToken = urlParams.get('github_token');

        if (githubToken) {
            localStorage.setItem('github_token', githubToken);
            window.history.replaceState({}, document.title, window.location.pathname);
        }

        checkTokens();
        fetchUserRole(); // Fetch role to check if the user is an admin

        const interval = setInterval(checkTokens, 1000);
        return () => clearInterval(interval);
    }, [navigate, location]);

    const handleGitHubLogin = () => {
        const token = localStorage.getItem('access_token');

        if (!token) {
            console.warn('No access token found. Redirecting to login.');
            window.location.href = '/login?error=no_token';
            return;
        }

        window.location.href = `/api/auth/github?access_token=${token}`;
    };

    const handleAddGitHubToken = async () => {
        try {
            const accessToken = localStorage.getItem('access_token');

            if (!accessToken) {
                alert("You must be logged in as an admin to add a GitHub token.");
                return;
            }

            if (!adminGitHubToken) {
                alert("Please enter a valid GitHub token.");
                return;
            }

            const response = await api.post('/auth/github/add-token', {
                github_token: adminGitHubToken
            });

            if (response.status === 200) {
                alert("GitHub token added successfully!");
                localStorage.setItem('github_token', adminGitHubToken); // Store the token in localStorage
            }
        } catch (error) {
            console.error("Error adding GitHub token:", error);
            alert("Failed to add GitHub token. Make sure you are an admin.");
        }
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.header}>Your_AI_Developer</h1>
            <div style={styles.loginBox}>
                <button style={styles.githubButton} onClick={handleGitHubLogin}>
                    Login with GitHub
                </button>

                {/* 🔒 Only show this section if the user is an admin & in development mode */}
                {process.env.NODE_ENV === 'development' && (
                    <div style={styles.adminInputBox}>
                        <input
                            type="text"
                            placeholder="Enter GitHub Token (DEV Mode Only)"
                            value={adminGitHubToken}
                            onChange={(e) => setAdminGitHubToken(e.target.value)}
                            style={styles.input}
                        />
                        <button style={styles.addTokenButton} onClick={handleAddGitHubToken}>
                            Add GitHub Token
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '50px',
        fontFamily: 'Arial, sans-serif',
    },
    header: {
        fontSize: '2rem',
        marginBottom: '20px',
    },
    loginBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '10px',
        width: '300px',
    },
    githubButton: {
        padding: '10px 20px',
        fontSize: '1.1rem',
        border: 'none',
        borderRadius: '5px',
        backgroundColor: '#333',
        color: '#fff',
        cursor: 'pointer',
        marginBottom: '20px',
    },
    adminInputBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20px',
    },
    input: {
        padding: '8px',
        width: '250px',
        marginBottom: '10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
    },
    addTokenButton: {
        padding: '10px 20px',
        fontSize: '1rem',
        border: 'none',
        borderRadius: '5px',
        backgroundColor: '#007BFF',
        color: '#fff',
        cursor: 'pointer',
    },
};

export default Login;
