import React, { useEffect, useState } from 'react';
import RepositoryManager from './RepositoryManager';
import TaskManager from './TaskManager';
import PRManager from './PRManager';
import PRButtons from './PRButtons'; 
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Dashboard.css';

const getBaseUrl = () => {
  switch (process.env.NODE_ENV) {
    case 'production':
      return process.env.REACT_APP_API_BASE_URL;
    case 'development':
    default:
      return 'http://localhost:5000';
  }
};

axios.defaults.baseURL = getBaseUrl();

const Dashboard = () => {
  const navigate = useNavigate();
  const [selectedRepo, setSelectedRepo] = useState('');
  const [taskManagerEnabled, setTaskManagerEnabled] = useState(false);
  const [prManagerEnabled, setPrManagerEnabled] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const requiredTaskSub = process.env.REACT_APP_STRIPE_PRICE_CODE;
  const requiredPRSub = process.env.REACT_APP_STRIPE_PRICE_REVIEW;
  const requiredCombinedSub = process.env.REACT_APP_STRIPE_PRICE_COMBINED;

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    const githubToken = localStorage.getItem('github_token');

    if (!accessToken || !githubToken) {
      navigate('/login', { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    axios.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
      return config;
    });
  }, []);

  useEffect(() => {
    axios.get('/api/user/subscriptions')
      .then(response => {
        setSubscriptions(response.data.subscriptions);
        console.log('Subscriptions:', response.data.subscriptions);
      })
      .catch(error => {
        console.error('Error fetching subscriptions:', error);
        navigate('/pricing');
      });
  }, [navigate]);

  const hasAccessToTaskManager = subscriptions.includes(requiredTaskSub) || subscriptions.includes(requiredCombinedSub);
  const hasAccessToPRManager = subscriptions.includes(requiredPRSub) || subscriptions.includes(requiredCombinedSub);

  const handleTaskManagerEnable = () => {
    if (hasAccessToTaskManager) {
      setTaskManagerEnabled(true);
      setPrManagerEnabled(false);
      console.log('Task Manager enabled');
    } else {
      navigate('/pricing');
    }
  };

  const handlePrManagerEnable = () => {
    if (hasAccessToPRManager) {
      setTaskManagerEnabled(false);
      setPrManagerEnabled(true);
      console.log('PR Manager enabled');
    } else {
      navigate('/pricing');
    }
  };

    // Handle Jira login
    const handleJiraLogin = () => {
      window.location.href = `${getBaseUrl()}/api/auth/jira`;
    };

  const handleGithubLogout = () => {
    localStorage.removeItem('github_token');
    window.location.reload(); // Refresh to apply changes
  };

  return (
    
    
    <div className="dashboard-container">
      {/* Logout Button */}
      <button className="logout-button" onClick={handleGithubLogout}>
        Logout from GitHub
      </button>
      <div className="dashboard-title">
        <h1>Dashboard</h1>
      </div>

            {/* Show Jira login button if not logged into Jira */}
      {/* {!jiraToken ? (
        <div>
          <p>You are logged in with GitHub. Would you like to connect to Jira?</p>
          <button onClick={handleJiraLogin}>Login with Jira</button>
        </div>
      ) : (
        <p>You are connected to Jira!</p>
      )} */}




      <RepositoryManager onSelectRepo={setSelectedRepo} />

      {selectedRepo && (
        <>
          <PRButtons
            showTaskManager={handleTaskManagerEnable}
            showPRManager={handlePrManagerEnable}
            disableTask={!hasAccessToTaskManager}
            disablePR={!hasAccessToPRManager}
          />

          {prManagerEnabled && <PRManager selectedRepo={selectedRepo} />}
          {taskManagerEnabled && <TaskManager selectedRepo={selectedRepo} />}
        </>
      )}
    </div>
  );
};

export default Dashboard;
